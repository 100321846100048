import React, { FC, useContext } from "react";
import { MegaTeaserProps } from "../generated-types";
import { sc, useSitecoreContext } from "~/foundation/Jss";
import { ContentWrapper } from "~/foundation/Components/ContentWrapper";
import { AspectRatio, Box, Flex, Heading, Text as ChakraText } from "@chakra-ui/react";
import { imageSizes, themable } from "~/foundation/Theme";
import { Ribbon } from "~/foundation/Components/Ribbon";
import { ExtendedBox } from "~/foundation/Components/ExtendedBox";
import { orientation } from "../base";
import { ColorThemeContext } from "~/foundation/Theme/ColorThemeContext";
import { RichTextStyling } from "~/foundation/Components/RichText/styles";
import { ImageSizeParameters } from "@sitecore-jss/sitecore-jss-react";

enum ImageRatio {
	Default,
	Uncropped
}

const MegaTeaser: FC<MegaTeaserProps> = ({ rendering, params, fields }) => {
	const imageLast: boolean = params.orientation === orientation.TextImage;
	const { sitecoreContext } = useSitecoreContext();
	const [theme] = useContext(ColorThemeContext);

	let imageRatio = ImageRatio.Default;
	let srcSet: ImageSizeParameters[] = [imageSizes.teaser.square.sm, imageSizes.teaser.square.md, imageSizes.teaser.square.lg];
	const smallerPadding = params?.spacing === "none" ? true : false;

	if (params.imageRatio === "1") {
		imageRatio = ImageRatio.Uncropped;
		srcSet = [imageSizes.teaser.uncroppedSquare.sm, imageSizes.teaser.uncroppedSquare.md, imageSizes.teaser.uncroppedSquare.lg];
	}

	const imgComponent = () => (
		<sc.AdvanceImage
			field={fields?.teaserImage}
			itemId={rendering.dataSource}
			fieldNames={["teaser image"]}
			sizes="(min-width: 1760px) 752px, (min-width: 1440px) 592px, (min-width: 768px) 482px, 100vw"
			srcSet={srcSet}
			sx={{ maxWidth: "100%", minWidth: "100%", borderRadius: "lg", borderBottomRightRadius: "0" }}
		/>
	)

	return (
		<Ribbon>
			<ContentWrapper
				py="0"
				pt={["var(--chakra-sizes-modulePY__SM)", null, "var(--chakra-sizes-modulePY__MD)", null, "var(--chakra-sizes-modulePY__XL)"]}
				pb={smallerPadding ? "0" : ["var(--chakra-sizes-modulePY__SM)", null, "var(--chakra-sizes-modulePY__MD)", null, "var(--chakra-sizes-modulePY__XL)"]}
				px={['var(--chakra-sizes-pageMarginSM)', null, "16", null, '32']}
			>
				<Flex alignItems="center" flexDirection={[imageLast ? "column-reverse" : "column", null, imageLast ? "row-reverse" : "row"]} gap={["12", null, "16", null, "6.875rem"]}>
					{(fields?.teaserImage?.value?.src || sitecoreContext.pageEditing) &&
						<Box
							flexBasis={["100%", null, "50%"]}
							w={["100%", null, "50%"]}
						>
							{imageRatio === ImageRatio.Uncropped ? (
								<>
									{imgComponent()}
								</>
							) : (
								<AspectRatio ratio={1} minWidth="100%">
									{imgComponent()}
								</AspectRatio>
							)}
							{(fields?.captionText?.value || sitecoreContext.pageEditing) &&
								<ChakraText variant="footerBody" maxW="482" mt={["4", null, "10"]} ms={["0", null, "12", null]} me={["4", null, "0", null]}>
									<sc.Text field={fields?.captionText} />
								</ChakraText>
							}
						</Box>
					}
					<ExtendedBox
						flex={["0 0 100%", null, "0 0 50%"]}
						w={["100%", null, "50%"]}
						pe="0"
						ps={["0", null, null, imageLast ? null : ".625rem"]}>
						{(fields?.teaserHeadline?.value || sitecoreContext.pageEditing) &&
							// Combines size h6 on mobile with size h2 on desktop
							<Heading
								as="h2"
								fontFamily={["body", null, null, "heading"]}
								fontSize={['lg', null, null, '5xl']}
								fontWeight="300"
								lineHeight={["2rem", null, null, "1"]}
								letterSpacing="wider">
								<sc.Text field={fields?.teaserHeadline}/>
							</Heading>
						}
						{(fields?.teaserDescription?.value || sitecoreContext.pageEditing) &&
							<Box mt={["4", null, "6"]} {...RichTextStyling(theme, sitecoreContext.custom.settings.isRtl)}>
								<sc.RichText field={fields?.teaserDescription} />
							</Box>
						}
						{(fields?.teaserLink?.value.href || sitecoreContext.pageEditing) &&
							<ExtendedBox mt={["4", null, "6"]}>
								<sc.Link field={fields?.teaserLink} variant="primary" size="md" color={theme.colors.ribbon.linkColor} />
							</ExtendedBox>
						}
					</ExtendedBox>
				</Flex>
			</ContentWrapper>
		</Ribbon>
	);
};

export default themable()(MegaTeaser);